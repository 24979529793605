.custom_input {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  label {
    margin-bottom: 10px;
    color: #777;
    font-size: 18px;
    span {
      color: #8f8f8f;
    }
  }

  &:focus-within {
    input,
    textarea {
      border: 2px solid #252525;
      // border-color: #252525;
    }

    label {
      color: #252525;
    }
  }

  input,
  textarea {
    border: 2px solid #eee;
    width: 100%;
    background: #f9fafb;
    padding: 10px;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    color: #252525;
    &::placeholder {
      font-family: "KanitLight";
    }
  }
}
