.scroll-up-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #fff;
  color: #252525;
  border: 2px solid #252525;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  display: none;
  transition: background-color 0.3s;
  width: 50px;
  height: 50px;
  @include for-phone-only {
    bottom: 20px;
    right: 20px;
  }
  &:hover {
    background-color: #252525;
    color: #fff;
  }
  svg {
    font-size: 25px;
  }
}

.scroll-up-button.show {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}
