.projects-item {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 50px;
  transition: all 0.2s, color 0.2s;

  a {
    text-decoration: none;
    color: #252525;
    display: block;
    img {
      width: 100%;
      display: block;
      object-fit: cover;
      object-position: top;
      height: 360px;
      border: 1px solid #e1e1e1;
      transition: all 0.2s, color 0.2s;
      border-radius: 4px;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
    }

    .proflie {
      p {
        font-family: "KanitMedium";
        margin: 0;
      }

      .name {
        font-family: "KanitMedium";
        font-size: 22px;
        margin-top: 20px;
      }

      .company {
        font-family: "KanitLight";
        color: #777;
        font-size: 20px;
        margin-top: -3px;
      }
    }
  }
}
