.main_button {
  background: transparent;
  border: 2px solid #252525;
  padding: 10px 50px;
  font-family: "KanitMedium";
  @include bezier-transition;
  border-radius: 4px;
  text-transform: uppercase;
  &:hover {
    background: #252525;
    border: 2px solid #252525;
    color: #fff;
  }
  @include for-phone-only {
    width: 100%;
  }
}

.green {
  background: #01b8b0;
  border: 2px solid #01b8b0;
  color: #fff;
  border-radius: 4px;
  &:hover {
    background: #fff;
    border: 2px solid #01b8b0;
    color: #01b8b0;
  }
}

.success {
  background: #28a745;
  border: 2px solid #28a745;
  color: #fff;
  border-radius: 4px;
  &:hover {
    background: #28a745;
    border: 2px solid #28a745;
    color: #fff;
  }
}

.error {
  background: #ff1229;
  border: 2px solid #ff1229;
  color: #fff;
  border-radius: 4px;
  &:hover {
    background: #ff1229;
    border: 2px solid #ff1229;
    color: #fff;
  }
}

.skin {
  background: #e9b077;
  border: 2px solid #e9b077;
  color: #f5f5f5;
  &:hover {
    background: #fff;
    border: 2px solid #e9b077;
    color: #252525;
  }
}

.black {
  background: #252525;
  border: 2px solid #252525;
  color: #fff;
  &:hover {
    // background: #454545;
    // border: 2px solid #454545;
    // color: #fff;

    background: #fff;
    border: 2px solid #252525;
    color: #252525;
  }
}

.white {
  background: #fff;
  border: 2px solid #fff;
  color: #252525;
  &:hover {
    background-color: #eee;
    border: 2px solid #eee;
    color: #252525;
  }
}
