.header {
  display: flex;
  // background: #fff;
  // position: absolute;
  background: transparent;
  position: fixed;
  top: 0px;
  z-index: 9999;
  height: 100px;
  width: 100%;
  transition: top 0.3s ease-in-out;
  // border-bottom: 1px solid #eee;
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // transition: all 3s ease-in-out;
    .links {
      gap: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      a {
        text-decoration: none;
        color: #252525;
        font-family: "KanitLight";
        font-size: 20px;
        background: transparent;
        display: block;
        &:hover {
          cursor: pointer;
          text-decoration: line-through;
          // font-family: "KanitBold";
        }
        @include for-laptop-s {
          display: none;
        }
      }
      .active {
        // text-decoration: line-through;
        font-family: "KanitBold";
      }
    }
    .black {
      display: block;
      @include for-laptop-s {
        display: none;
      }
    }
    .menu {
      display: none;
      @include for-laptop-s {
        display: block;
      }
    }
  }
}

.fixed-header {
  z-index: 99;
  transition: top 0.3s ease-in-out;
  border: none;
  position: fixed;
  background: rgba(255, 247, 239, 0.24);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.4px);
  -webkit-backdrop-filter: blur(8.4px);
}

.menu-container {
  position: fixed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  z-index: 9999;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .menu {
    background-color: white;
    width: 100%;
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1000;
    .menu-items {
      list-style: none;
      padding: 0 20px;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      gap: 40px;
      a {
        text-decoration: none;
        margin: 10px 0;
        cursor: pointer;
        text-decoration: none;
        color: #252525;
        font-size: 20px;
        font-family: "KanitLight";
        font-size: 20px;
      }
      .active {
        // text-decoration: line-through;
        font-family: "KanitMedium";
      }
    }

    .close-button {
      cursor: pointer;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100px;
      svg {
        display: block;
        font-size: 35px;
        // background-color: #eee;
        // border-radius: 50%;
        // padding: 5px;
        color: black;
      }
    }
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
  }
}
