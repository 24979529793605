.progress-loader {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.progress-loader {
  width: 180px;
  background: rgba(236, 236, 238, 0.253);
  height: 3px;
  border-radius: 7px;
}

.progress {
  content: "";
  width: 1px;
  height: 5px !important;
  border-radius: 7px;
  background: rgb(255, 255, 255);
  transition: 0.5s;
  animation: loading1274 2s ease infinite;
}

@keyframes loading1274 {
  0% {
    width: 0%;
  }

  10% {
    width: 10%;
  }

  50% {
    width: 40%;
  }

  60% {
    width: 60%;
  }

  100% {
    width: 100%;
  }
}
