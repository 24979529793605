.banner_bg {
  padding-top: 150px;
  padding-bottom: 100px;
  background-color: #f9fafb;
  // background-color: #252525;
  position: relative;
  z-index: 0;
  overflow: hidden;
  .shape1 {
    z-index: -1;
    width: 300px;
    height: 300px;
    background-color: #f0f0f0;
    border-radius: 50%;
    position: absolute;
    top: 64px;
    left: -200px;
    @include for-tablet-landscape {
      width: 240px;
      height: 200px;
    }
  }
  .shape3 {
    z-index: -1;
    width: 256px;
    height: 256px;
    background-color: #f0f0f0;
    border-radius: 50%;
    position: absolute;
    bottom: -96px;
    right: -96px;
    @include for-tablet-landscape {
      width: 200px;
      height: 200px;
    }
  }
  h1 {
    font-family: "KanitMedium";
    width: 60%;
    font-size: 48px;
    @include for-tablet-landscape {
      width: 100%;
    }
  }
  p {
    font-family: "KanitLight";
    font-size: 24px;
    width: 40%;
    color: #777;
    @include for-tablet-landscape {
      width: 100%;
    }
  }
  a {
    font-family: "KanitLight";
    font-size: 20px;
    color: #252525;
    cursor: pointer;
  }
}
