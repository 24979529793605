@import "../utils/mixins";
@import "../utils/animation";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "KanitLight", sans-serif;
  overflow-x: hidden;
  background-color: #fff;
  // cursor: url("../../assets/images/thanos.png"), auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
  select {
    background: transparent;
    border: none;
    font-size: 20px;
    font-family: "KanitLight";
  }
}

body iframe {
  z-index: -2;
  height: 99.5vh;
  width: 100%;
  cursor: inherit;
}

*::selection {
  color: #252525;
  background-color: #eee;
}

*:focus {
  outline: none;
}

svg {
  cursor: pointer;
}

h1,
h2 {
  font-family: "KanitSemiBold";
}

.display-flex {
  display: flex;
  align-items: center;
  @include for-tablet-landscape {
    flex-direction: column;
  }
}

.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}

.mb40 {
  margin-bottom: 40px;
}
.mt50 {
  margin-top: 50px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-25 {
  gap: 25px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

.pointer {
  cursor: pointer;
}

.lines {
  position: fixed;
  z-index: -15;
  width: 100%;
  height: 100vh;
  top: 0;
  background: url(../../assets/images/lines.png);
  background-size: auto;
  background-position: center;
  background-repeat: repeat-y;
}

// HOME PAGE CSS

section {
  margin-top: 100px;
  @include for-phone-only {
    margin-top: 60px;
  }
}

.banner_wrapper {
  background: #252525;
}

#banner {
  position: relative;
  display: flex;
  justify-content: center;
  // text-align: left;
  @include for-tablet-landscape {
    flex-direction: column-reverse;
  }
  h1 {
    font-family: "KanitBold";
  }

  .banner_left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    .info1 {
      font-family: "KanitMedium";
    }
    .about_me {
      font-family: "KanitLight";
      font-size: 24px;
      line-height: 1.5;
    }
    .line_curved {
      margin-top: 30px;
      margin-bottom: 30px;
      width: 500px;
    }
    .banner_buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      @include for-phone-only {
        flex-direction: column;
        button {
          width: 100%;
        }
      }
    }
    .skill-tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      width: 80%;
      @include for-tablet-landscape {
        width: 100%;
      }
    }

    span {
      font-size: 30px;
      font-family: "KanitBold";
      color: #43b97f;
    }
  }

  .banner_right {
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    @include for-tablet-landscape {
      flex-direction: column-reverse;
    }
    .profile_img {
      width: 650px;
      @include for-tablet-landscape {
        width: 100%;
      }
    }
  }
}

#guarantee {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  font-family: "KanitLight";
  @include for-tablet-landscape {
    grid-template-columns: 1fr;
  }
  .guarantee-left {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    @include for-tablet-landscape {
      justify-content: center;
    }
    .guarantee-left-flex {
      flex-direction: column;
      display: flex;
      gap: 5px;
      margin-bottom: 30px;
      @include for-tablet-landscape {
        // width: 100%;
        margin-bottom: 20px;
      }
      p {
        font-size: 24px;
      }
    }
    h1 {
      font-family: "KanitLight";
      margin-bottom: 30px;
      font-size: 40px;
      @include for-tablet-landscape {
        font-size: 32px;
        margin-bottom: 20px;
      }
    }
  }
  .guarantee-right {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    img {
      max-width: 100%;
      display: inline-block;
    }
  }
}

#process {
  background: #252525;
  padding: 100px;
  color: #fff;
  position: relative;
  z-index: 10;
  overflow: hidden;
  font-family: "KanitLight";
  @include for-tablet-landscape {
    padding: 50px 0px;
  }
  .shape1 {
    z-index: -1;
    width: 300px;
    height: 300px;
    background-color: #353535;
    border-radius: 50%;
    position: absolute;
    top: 64px;
    left: -200px;
    @include for-tablet-landscape {
      width: 240px;
      height: 200px;
    }
  }
  .shape2 {
    z-index: -1;
    width: 256px;
    height: 256px;
    background-color: #353535;
    border-radius: 50%;
    position: absolute;
    top: -128px;
    right: 200px;
    @include for-tablet-landscape {
      width: 200px;
      height: 200px;
    }
  }
  .shape3 {
    z-index: -1;
    width: 256px;
    height: 256px;
    background-color: #353535;
    border-radius: 50%;
    position: absolute;
    bottom: -96px;
    right: -96px;
    @include for-tablet-landscape {
      width: 200px;
      height: 200px;
    }
  }
  h1 {
    font-family: "KanitMedium";
    font-size: 40px;
  }
  .process-sub {
    font-family: "KanitLight";
    font-size: 24px;
    width: 50%;
    @include for-tablet-landscape {
      width: 100%;
    }
  }
  .process-roadmap {
    margin-top: 80px;
    position: relative;
    .roadmap-line {
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 47px;
    }
    .roadmap-line-2 {
      width: 100%;
      height: 2px;
      background-color: #fff;
      display: none;
      position: absolute;
      top: 47px;
      @include for-tablet-landscape {
        display: block;
        top: 250px;
      }
    }

    .process-roadmap-wrap {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      z-index: 10;
      grid-column-gap: 32px;
      grid-row-gap: 32px;
      padding: 0;
      position: relative;
      @include for-tablet-landscape {
        grid-template-columns: 1fr 1fr;
      }
      .roadmap-cell {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        .roadmap-time {
          letter-spacing: 0.5px;
          font-size: 18px;
          line-height: 12px;
          font-weight: 600;
        }
        .roadmap-point {
          width: 24px;
          height: 24px;
          background-color: #252525;
          border: 2px solid #fff;
          border-radius: 50%;
          justify-content: center;
          align-items: center;
          margin-top: 24px;
          margin-bottom: 24px;
          display: flex;
          .roadmap-point-inside {
            width: 6px;
            height: 6px;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        .roadmap-item-description {
          width: 300px;
          max-width: 250px;
          color: #fff;
          font-size: 20px;
          padding: 0;
          margin: 0;
          @include for-tablet-landscape {
            width: 220px;
          }
        }
      }
    }
  }
}

#testimonials {
  background: #f9fafb;
  padding: 100px;
  @include for-tablet-landscape {
    padding: 50px 0px;
  }
  h1 {
    font-family: "KanitMedium";
    font-size: 40px;
  }

  .testimonials-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 20px;
    margin-top: 50px;
    &-item {
      background: #fff;
      padding: 30px;
      border-radius: 4px;
      &-desc {
        font-family: "KanitLight";
        font-size: 18px;
        margin-top: 20px;
        span {
          font-family: "KanitLight";
          font-size: 18px;
          font-weight: 800;
        }
      }
      &-profile {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        img {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: block;
        }
        .proflie {
          p {
            font-family: "KanitMedium";
            margin: 0;
          }
          .name {
            font-family: "KanitMedium";
          }
          .company {
            font-family: "KanitLight";
            color: #777;
            margin-top: -3px;
          }
        }
      }
    }
  }
}

#faq {
  font-family: "KanitLight";
  h1 {
    font-family: "KanitMedium";
    font-size: 40px;
  }

  .keywords {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
    p {
      background: transparent;
      padding: 10px 20px;
      border-radius: 50px;
      border: 2px solid #eee;
      color: #777;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
      svg {
        font-size: 22px;
        color: #252525;
      }
    }
    .active {
      // background-color: #252525;
      border: 2px solid #252525;
      color: #252525;
    }
    .clear_keyword {
      display: flex;
      align-items: center;
      gap: 15px;
      background: #252525;
      padding: 10px 20px;
      border-radius: 50px;
      border: 2px solid #252525;
      color: #fff;
    }
  }
  .search {
    position: relative;
    input[type="text"] {
      padding-left: 50px;
    }
    .search-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 30px;
    }
    input {
      border: 1px solid #eee;
      background: transparent;
      padding: 15px;
      font-family: "KanitLight";
      font-size: 20px;
      width: 100%;
      border-radius: 4px;
      &::placeholder {
        margin-left: 20px;
      }
    }
  }

  .questions {
    margin-top: 50px;
    .no_questions_found {
      font-family: "KanitLight";
      font-size: 25px;
      text-align: center;
    }
  }
}

#services {
  font-family: "KanitLight";
  .services_wrapper {
    margin-bottom: 100px;
    h1 {
      font-family: "KanitMedium";
      font-size: 40px;
    }
    .services_wrapper_p {
      font-family: "KanitLight";
      font-size: 24px;
      width: 40%;
      color: #777;
      @include for-tablet-landscape {
        width: 100%;
      }
    }
    .services_wrapper_container {
      display: grid;
      grid-template-rows: auto auto auto;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 32px;
      grid-row-gap: 48px;
      margin-top: 64px;
      padding: 0;
      grid-auto-columns: 1fr;
      justify-content: center;
      @include for-tablet-portrait {
        grid-template-columns: 1fr;
      }
      .services_wrapper_item {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
        .service {
          width: 100%;
          grid-column-gap: 24px;
          grid-row-gap: 24px;
          align-items: flex-start;
          padding-right: 16px;
          display: flex;
          img {
            max-width: 100%;
            display: inline-block;
          }
          .service-info {
            width: 100%;
            grid-column-gap: 8px;
            grid-row-gap: 8px;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 4px;
            display: flex;
            h3 {
              font-size: 22px;
              font-weight: 600;
            }
            p {
              font-size: 20px;
              color: #777;
            }
          }
        }
      }
    }
  }
}

#blog {
  background: #f9fafb;
  padding: 100px;
  @include for-tablet-landscape {
    padding: 50px 0px;
  }
  h1 {
    font-family: "KanitMedium";
    font-size: 40px;
  }
  .blog-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    margin-top: 50px;
    transition: all 0.2s, color 0.2s;
    .wrapper-item {
      font-family: "KanitLight";
      text-decoration: none;
      // max-width: 350px;
      background: #fff;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      transition: all 0.2s, color 0.2s;
      &:hover {
        transform: scale(1.05);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
      img {
        width: 100%;
      }
      .wrapper-item-content {
        padding: 25px;
        h3 {
          font-weight: 600;
          font-size: 20px;
          color: #252525;
        }
        p {
          font-size: 18px;
          color: #777;
        }
      }
    }
  }
}

#contact {
  text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  font-family: "KanitLight";
  .contact_header {
    background: #f9fafb;
    padding-top: 150px;
    padding-bottom: 150px;
    .contact_width {
      margin: 0 auto;
      width: 75%;
      @include for-tablet-landscape {
        width: 100%;
      }
      h1 {
        font-family: "KanitBold";
      }
      p {
        font-family: "KanitLight";
        font-size: 24px;
        color: #777;
      }
    }
  }
  .contact_form {
    margin-top: -100px;
    margin-bottom: 50px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 10px;
    display: flex;
    gap: 20px;
    border-radius: 20px;
    @include for-tablet-landscape {
      flex-direction: column;
    }
    &_left {
      width: 30%;
      // background: #01b8b0;
      background: #252525;

      color: #fff;
      border-radius: 20px;
      text-align: left;
      padding: 30px;
      height: auto;
      position: relative;
      overflow: hidden;
      z-index: 9;
      @include for-tablet-landscape {
        width: 100%;
      }
      .shape {
        width: 200px;
        z-index: -1;
        height: 200px;
        // background: rgb(141, 223, 219);
        // background: linear-gradient(
        //   130deg,
        //   rgba(141, 223, 219, 1) 0%,
        //   rgba(104, 213, 208, 1) 48%,
        //   rgba(67, 202, 196, 1) 100%
        // );
        background-color: #353535;
        // background-color: #71d7d3;
        border-radius: 50%;
        position: absolute;
        bottom: -60px;
        right: -40px;
      }
      h2 {
        font-family: "KanitMedium";
      }
      p {
        font-family: "KanitLight";
        font-size: 18px;
        padding: 0;
        margin: 0;
      }
      .contact_form_info {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 30px;
        .contact_form_info_item {
          display: flex;
          align-items: center;
          gap: 20px;
          svg {
            font-size: 26px;
          }
          a {
            text-decoration: none;
            color: #f5f5f5;
            font-family: "KanitLight";
            font-size: 18px;
          }
        }
      }
    }
    &_right {
      width: 70%;
      padding: 3%;
      text-align: left;
      font-family: "KanitMedium";
      display: flex;
      flex-direction: column;
      gap: 40px;
      @include for-tablet-landscape {
        width: 100%;
      }
    }
  }
}

#subscription {
  .subscription-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #252525;
    padding: 100px;
    text-align: center;
    color: #fff;
    gap: 20px;
    margin: 0 auto;
    @include for-tablet-landscape {
      padding: 50px 20px;
    }
    h1 {
      font-family: "KanitMedium";
      font-size: 40px;
      @include for-tablet-landscape {
        font-size: 35px;
      }
    }
    P {
      font-family: "KanitLight";
      font-size: 25px;
      width: 50%;
      @include for-tablet-landscape {
        width: 75%;
      }
      @include for-phone-only {
        width: 100%;
      }
    }
  }
}

#consulation {
  background-color: #f9fafb;
}

#footer {
  background: #fff;
  width: 100%;
  padding: 30px 0px;
  font-family: "KanitLight";
  .footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include for-phone-only {
      gap: 20px;
      flex-direction: column;
    }
    p {
      color: #252525;
      font-size: 20px;
      margin: 0;
    }
    &-socials {
      display: flex;
      align-items: center;
      gap: 20px;
      // justify-content: center;
      svg {
        font-size: 30px;
        color: #252525;
        path {
          stroke: #252525;
        }
      }
    }
  }
}

#review {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  font-family: "KanitLight";
  min-height: 100vh;

  h1 {
    font-family: "KanitMedium";
    font-size: 50px;
    text-align: center;
  }

  p {
    font-family: "KanitLight";
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    max-width: 1000px;

    @media (max-width: 768px) {
      font-size: 20px;
      max-width: 500px;
      padding: 0 10px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    padding: 40px 20px;
    background-color: white;
    border-radius: 8px;
    width: 50%;
    gap: 15px;
    max-width: 600px;

    @media (max-width: 768px) {
      width: 90%;
    }
  }

  form div {
    width: 100%;
  }

  form input[type="text"],
  form textarea {
    width: 100%;
    background-color: transparent;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 4px;
    font-size: 1rem;
  }

  form textarea {
    height: 150px;
    resize: vertical;
  }

  .file-input-container {
    position: relative;
    cursor: pointer;
    width: 150px;
    height: 150px;
  }

  .file-input-container input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .file-input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid #ccc;
    color: #777;
    font-family: "KanitLight";
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
  }

  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .css-iljtu2-MuiRating-root {
    font-size: 2rem !important;
  }

  .submission-error {
    font-family: "KanitLight";
    font-size: 30px;
    text-align: center;
    position: absolute;
    background: #ff0000;
    border-radius: 10px;
    padding: 20px;
    color: #fff;
    top: 20px;
    right: 10px;
  }
}
