.scroll {
  width: 100%;
  overflow: hidden;
  background-color: #f9fafb;
  .scroll-slider {
    animation: scrollAnimation 20s linear infinite;
    padding: 30px 0px;
    z-index: 10;
    height: 100px;
    gap: 65px;
    flex: none;
    align-items: center;
    display: flex;
    position: relative;
    transform-style: preserve-3d;
    img {
      // width: 200px;
      max-width: 100% !important;
      display: inline-block !important;
      @include bezier-transition;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.paused {
  animation-play-state: paused;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.clients {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 50px;
  padding: 30px 0px;
  align-items: center;
  .client-image {
    max-width: 100%;
    height: auto;
    @include bezier-transition;
    &:hover {
      transform: scale(1.2);
    }
  }
}
