@font-face {
  font-family: "KanitLight";
  src: local("KanitLight"),
    url("../../assets/fonts/Kanit-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "KanitMedium";
  src: local("KanitMedium"),
    url("../../assets/fonts/Kanit-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "KanitSemiBold";
  src: local("KanitSemiBold"),
    url("../../assets/fonts/Kanit-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "KanitBold";
  src: local("KanitBold"),
    url("../../assets/fonts/Kanit-Bold.ttf") format("truetype");
}
