.header_title {
  h1 {
    font-family: "KanitMedium";
    font-size: 40px;
  }
  p {
    font-family: "KanitLight";
    font-size: 25px;
  }
}
